<template>
  <div class="view-pdf">
    <div class="view-pdf__header _flex _flex-justify__between _flex-align__center">
      <div class="view-pdf__header__title _font-weight__600 _font-size__heading3">
        {{ pdfName.replaceAll('+', ' ') }}
      </div>
      <div class="view-pdf__header__operate">
        <el-button
          type="default"
          icon="el-icon-s-fold"
          @click="
            $router.push({
              path: '/analysis',
              query: { tabsIndex: 1 }
            })
          "
          >Go to Defect List</el-button
        >
        <el-button type="info" @click="$router.back()">Back</el-button>
        <el-button type="primary" icon="el-icon-download" @click="handleDownload"
          >Download</el-button
        >
      </div>
    </div>

    <div class="view-pdf__content">
      <div class="content-operate _flex _flex-align__center">
        <span class="_font-size__sub__heading _font-weight__600">
          {{ pdfName.replace(/[+.]|pdf/g, ' ') }}
        </span>

        <div class="content-operate__button _flex _flex-align__center">
          <div class="page">
            <span class="active num">{{ page }}</span> / <span class="num">{{ pageCount }}</span>
          </div>

          <div class="zoom">
            <i class="el-icon-minus" @click="scaleFun('small')"></i>
            <span class="num active">{{ scale }}%</span>
            <i class="el-icon-plus" @click="scaleFun('big')"></i>
          </div>

          <div class="rotate _flex _flex-align__center">
            <i class="page-turning _flex _flex-wrap _flex-justify__center">
              <i class="el-icon-caret-top" @click="page = page === 1 ? 1 : page - 1"></i>
              <i
                class="el-icon-caret-bottom"
                @click="page = page === pageCount ? pageCount : page + 1"
              ></i>
            </i>
            <i class="el-icon-refresh-left" @click="rotate += 90"></i>
          </div>
        </div>
      </div>

      <el-scrollbar class="content-pdf" :style="{ width: width + 'px' }">
        <pdf
          class="vue-pdf"
          ref="pdf"
          :page="page"
          :src="$route.query.src"
          :rotate="rotate"
          @num-pages="pageCount = $event"
          @link-clicked="page = $event"
          @progress="handleProcess"
        ></pdf>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import pdf from 'vue-pdf'
import { downloadPDF } from '@/utils/batch-save'

const width = window.innerWidth - 100 - 64
export default {
  components: {
    pdf
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      rotate: 0,
      // 985 为 PDF 宽度
      scale: parseInt((985 / width) * 100),
      width,
      loading: null,
      baseurl: process.env.VUE_APP_BASEURL,

      pdfName: ''
    }
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      target: document.getElementsByClassName('content-pdf')[0],
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      text: '0%'
    })

    // eslint-disable-next-line prefer-destructuring
    this.pdfName = this.$route.query.src.match(/pdf\/(.*)/)[1]
  },
  methods: {
    handleDownload() {
      downloadPDF(this.$route.query.src, this.pdfName)
    },
    handleProcess(e) {
      console.log(this.loadedRatio)
      if (e === 1) {
        this.loading.close()
      }
      this.loading.text = `${(e * 100).toFixed(2)}%`
    },
    scaleFun(type = 'small') {
      if (this.scale === 100 && type === 'small') {
        return
      }
      this.scale += type === 'small' ? -5 : 5
      this.$refs.pdf.$el.style.width = `${parseInt(this.scale)}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.view-pdf {
  &__header {
    margin-bottom: 30px;
  }

  &__content {
    background: #eaeaea;
    .content-operate {
      width: 100%;
      height: 56px;
      background: #dddfe1;
      padding: 0 15px;

      &__button {
        margin-left: 80px;
        font-size: 12px;
        color: #6f6f6f;

        .active {
          background: #6f6f6f;
          color: var(--color-white);
          margin: 0 20px;
        }

        .num {
          display: inline-block;
          padding: 4px 8.5px;
        }

        > div {
          padding: 0 30px;
          border-right: 1px solid #6f6f6f;
        }

        i {
          font-weight: 600;
          font-size: 16px;
        }

        i + i {
          display: inline-block;
          margin-left: 15px;
        }

        .page-turning {
          position: relative;
          width: 16px;
          height: 30px;

          i {
            position: absolute;
            top: 0;
            margin-left: 0;
          }

          .el-icon-caret-top {
            top: 3px;
          }
          .el-icon-caret-bottom {
            top: 11px;
          }
        }

        .page .active {
          margin-right: 10px;
        }
      }
    }

    .content-pdf {
      padding: 20px 0;
      height: calc(var(--main-height-no-nav) - 66px - 56px);
      .vue-pdf {
        display: block;
        margin: 0 auto;
        width: 985px;
      }
    }
  }
}
</style>
